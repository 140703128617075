import React from 'react';

import '../../style/Header.css';

function Header(props) {

    return (
        <div className='header-container' style={{backgroundColor: "#F5F3ED"}}>
            { !props.hasLogo && <h1 className='header-title' style={{color: '#95ACBC'}}>{props.barName}</h1>}
        </div>
    );
}

export default Header;
