import React, { useEffect, useState } from "react";

import { useStateContext } from '../../contexts/ContextProvider';

import "../../style/Buscador.css";

import { ReactComponent as Lupa } from "../../assets/lupa.svg";

const Buscador = (props) => {
    const carta = props.carta;
    const [platoBuscado, setPlatoBuscado] = useState('');
    const [platosCompleto, setPlatosCompleto] = useState([]);

    const { setTooltipData, setTooltipOpened } = useStateContext();
  
    useEffect(() => {
        Object.keys(carta).forEach((item) => {
            const listaPlatos = carta[item] ?? [];
            listaPlatos.forEach((plato) => {
                setPlatosCompleto((platosCompleto) => {
                    return [...new Set([...platosCompleto, plato.nombre])];
                });
            });
        });
    }, [carta]);

    const openTooltip = () => {
        let platoData;
        Object.keys(carta).forEach(element => {
            const listaPlatos = carta[element];
            listaPlatos.forEach((plato) => {
                if (plato.nombre === platoBuscado) {
                    platoData = plato;
                    setTooltipOpened(platoBuscado);
                    setTooltipData(platoData);
                    return;
                }
            });
        });
    }

    const handleChange = (event) => {
        setPlatoBuscado(event.target.value);
        if (event.key === 'Enter') {
            openTooltip();
        }
    }

    return (
        <div className="buscador-de-platos-contenedor">
            <input 
                type="text" 
                name="plato" 
                list="platos" 
                autoComplete="on" 
                className="buscador-entrada" 
                placeholder="Buscar productos..." 
                onChange={handleChange} 
                onKeyDownCapture={(event) => {
                    if (event.key === 'Enter') {
                        openTooltip();
                    }
                }} 
            />
            <button className="buscador-boton" style={{backgroundColor: ``}} onClick={() => openTooltip()}>
                <Lupa className="buscador-icono" width="20px" height="20px" />
            </button>
            <datalist id="platos">
                {platosCompleto.map((item) => {
                    return (
                        <option key={`key-plato-${item}`} value={item} />
                    );
                })}
            </datalist>
        </div>
    );
}

export default Buscador;
