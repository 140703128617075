import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {

    const [barName, setBarName] = useState('');
    const [tooltipData, setTooltipData] = useState({});
    const [tooltipOpened, setTooltipOpened] = useState('');
    
    return (
        <StateContext.Provider value={{ barName, setBarName, tooltipData, setTooltipData, tooltipOpened, setTooltipOpened }}>
        {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);