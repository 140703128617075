import React, { useState } from "react";

import "../../style/Entrada.css";

import { useStateContext } from '../../contexts/ContextProvider';

const Entrada = (props) => {

    const tipo = props.tipo;
    const listado = props.listado;
    const divisa = props.divisa;
    const alergenos = props.alergenos;
    const filteredAlergenos = props.filteredAlergenos;
    const { setTooltipData, setTooltipOpened } = useStateContext();

    const [dropped, setDropped] = useState(true);

    const isFiltered = (plato) => {
        filteredAlergenos.forEach((alergeno) => {
            if (plato.alergenos.includes(parseInt(alergeno))) {
                return true;
            }
        });
        return false;
    }

    return (
        <div className="contenedor-entrada">
            <div className="header-entrada-container">
                <h2 className="header-entrada"  onClick={() => {setDropped(!dropped)}}>{tipo}</h2>
                <svg viewBox="0 0 16 10" width={20} className="header-entrada-icono"  onClick={() => {setDropped(!dropped)}}>
                    {dropped ? <path d="M 0 0 L 5 0 L 8 4 L 11 0 L 16 0 L 8 9 L 0 0 Z" /> : <path d="M 5 8 L 0 8 L 8 0 L 16 8 L 11 8 L 8 5 L 5 8 Z" />}
                </svg>
            </div>

            {
                dropped &&
                <div>
                    {
                        listado && listado.map((item, index) => {
                            return (
                                !isFiltered(item) && <div className="entrada" key={`entrada-${item.nombre}-${index}`} style={{backgroundColor: index % 2 === 0 ? '#e3e3e3' : 'white'}}>
                                    <div className="entrada-izquierda">
                                        <p className="entrada-nombre" onClick={() => {setTooltipOpened(item.nombre); setTooltipData(item);}}>{item.nombre}</p>
                                        <p className="entrada-alergeno">
                                            {
                                                item.alergenos.map((id, index) => {
                                                    const alergeno = alergenos[id].toLowerCase().replace(/ /g, "-");
                                                    return (
                                                        <img src={require(`../../style/alergenos/${alergeno}-alergenos-alimentarios-icono.png`)} 
                                                        alt={alergenos[id]} 
                                                        className="plato-alergenos"
                                                        key={`alergeno-${index}-${id}-${item.nombre}`}
                                                        />
                                                    )
                                                })
                                            }
                                        </p>
                                    </div>
                                    <div className="entrada-derecha">
                                        {
                                            item.precios.map((precio, index) => {
                                                return (
                                                    <p key={`entrada-precio-${precio.precio}-${index}`} className="entrada-precio">{precio.descripcion}: {precio.precio} {divisa}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }

        </div>
    );
}

export default Entrada;