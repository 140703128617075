import React, { useState } from "react";
import { SketchPicker } from 'react-color';

import "../../style/Register/Personalization.css";

const Personalization = (props) => {

    const [backgroundColor, setBackgroundColor] = useState('#d4ffef');
    const [headerAndFooterColor, setHeaderAndFooterColor] = useState('#6ec3d4');
    const [barName, setBarName] = useState('Ejemplo de nombre');
    const [barNameColor, setBarNameColor] = useState('#000000');

    const ColorPicker = ({color, setColor}) => {
        const handleChange = (color) => {
            setColor(color.hex);
        };
    
        return (
            <div className="colorPicker">
                <SketchPicker
                    color={color}
                    onChangeComplete={handleChange}
                    disableAlpha={true}
                    presetColors={[]}
                />
            </div>
        );
    }

    const onSendData = () => {
        props.setFormData({
            ...props.formData,
            personalization: {
                backgroundColor,
                headerAndFooterColor,
                barName,
                barNameColor
            }
        });
        props.setStep(4);
    };

    const [colorUncollapsed, setColorUncollapsed] = useState('');

    const colorChanging = (colorKey) => { 
        if (colorUncollapsed === colorKey) {
            setColorUncollapsed('');
        } else {
            setColorUncollapsed(colorKey);
        }
    }

    return (
        <div className="personalization-container">
            <div className="flex-container">
                <div className="personalization-buttons">
                    <button className="button-30" onClick={() => colorChanging('backgroundColor')}>Color de Fondo</button>
                    { colorUncollapsed === 'backgroundColor' && <ColorPicker color={backgroundColor} setColor={setBackgroundColor} /> }
                    <button className="button-30" onClick={() => colorChanging('headerAndFooterColor')}>Color de Cabecera y pie de página</button>
                    { colorUncollapsed === 'headerAndFooterColor' && <ColorPicker color={headerAndFooterColor} setColor={setHeaderAndFooterColor} /> }
                    <button className="button-30" onClick={() => colorChanging('barNameColor')}>Color del Título</button>
                    { colorUncollapsed === 'barNameColor' && <ColorPicker color={barNameColor} setColor={setBarNameColor} /> }
                </div>
                <div className="personalization_global">
                    <div className="personalization_header" style={{backgroundColor: headerAndFooterColor}}>
                        <input type="text" onChange={(e) => setBarName(e.target.value)} value={barName.toUpperCase()} style={{color: barNameColor}} />
                    </div>
                    <div className="personalization_body" style={{backgroundColor: backgroundColor}}></div>
                    <div className="personalization_footer" style={{backgroundColor: headerAndFooterColor}}></div>
                </div>
            </div>
            <button className="button-30" type="submit" onClick={() => onSendData()}>Aceptar</button>
        </div>
    );
};

export default Personalization;
