import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, Carta, AllBars, Register } from './pages';

function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:barId/:barName" element={<Carta />} />
        <Route path="/all" element={<AllBars />} />
        <Route path="/new" element={<Register />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
