import React, { useState } from "react";
import Personal from "../components/Register/Personal";
import Plans from "../components/Register/Plans";
import Personalization from "../components/Register/Personalization";

const Register = () => {
    
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        personal: {
            nombre: "",
            apellido: "",
            dni: "",
            correo: "",
            contraseña: "",
            contraseña2: ""
        },
        plan: "",
        personalization: {
            backgroundColor: '#d4ffef',
            headerAndFooterColor: '#6ec3d4',
            barName: 'Ejemplo de nombre',
            barNameColor: '#000000'
        }
    });

    return (
        <div>
            {
                step === 1 ? <Personal setStep={setStep} formData={formData} setFormData={setFormData} /> : null
            }
            {
                step === 2 ? <Plans setStep={setStep} formData={formData} setFormData={setFormData} /> : null
            }
            {
                step === 3 ? <Personalization setStep={setStep} formData={formData} setFormData={setFormData} /> : null
            }
        </div>
    );
};

export default Register;