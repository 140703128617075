import React from "react";

import "../../style/Tooltip.css";

const Tooltip = (props) => {

    const data = props.data;

    return (
        <div className="tooltip">
            <div className="tooltip-header">
                <h3 className="tooltip-header-text">{props.plato}</h3>
                <svg viewBox="0 0 16 16" width={20} className="tooltip-header-close-icono" onClick={props.close}>
                    <path d="M 0 0 L 3 0 L 4 2 L 5 0 L 8 0 L 6 3 L 8 6 L 5 6 L 4 4 L 3 6 L 0 6 L 2 3 L 0 0 Z" />
                </svg>
            </div>
            <div className="tooltip-body">
                <div className="tooltip-body-alergenos">
                    {data.alergenos?.length > 0 && <h4>Alergenos</h4>}
                    {data.alergenos?.map((id) => {
                        if (id === 0) return null;
                        const alergeno = props.alergenos[id].toLowerCase().replace(/ /g, "-");
                        return (
                            <div className="alergeno-tooltip-container" key={`imgs-tabla-alergeno-${id}`} >
                                <img src={require(`../../style/alergenos/${alergeno}-alergenos-alimentarios-icono.png`)} alt={props.alergenos[id]} className="alergeno-tooltip" />
                                <p className="alergeno-tooltip-text">{props.alergenos[id]}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="tooltip-body-precios">
                    <h4>Precios</h4>
                    <div className="tooltip-body-precios-container">
                        {
                            data.precios?.map((precio) => {
                                return (
                                    <div className="tooltip-body-precios-precio" key={`tooltip-precio-${precio.precio}`}>
                                        <p className="tooltip-body-precios-precio-text">{precio.descripcion}: {precio.precio}{props.divisa}</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tooltip;