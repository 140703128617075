import React from "react";

import "../../style/Alergenos.css";

const Alergenos = (props) => {

    const alergenos = props.alergenos;
    const filteredAlergenos = props.filteredAlergenos;
    const setFilteredAlergenos = props.setFilteredAlergenos;

    const filterAlergenos = (alergeno) => {
        setFilteredAlergenos((filteredAlergenos) => {
            if (filteredAlergenos.includes(alergeno)) {
                return filteredAlergenos.filter((a) => a !== `${alergeno}`);
            } else {
                return [...filteredAlergenos, alergeno];
            }
        });
    }

    return (
        <div className="alergenos">
            <div className="alergenos-container">
                {Object.keys(alergenos).map((id) => {
                    const alergeno = alergenos[id].toLowerCase().replace(/ /g, "-");
                    return (
                        <div className="button-container" key={`key-alergenos-${alergeno}`}>
                            <button key={`boton-alergeno-${id}`} className="boton-alergeno" onClick={() => {filterAlergenos(id)}}>
                                <img src={require(`../../style/alergenos/${alergeno}-alergenos-alimentarios-icono.png`)} 
                                    alt={alergenos[id]} 
                                    style={{filter: !filteredAlergenos.includes(id) ? '' : 'grayscale(100%)'}} />
                            </button>
                            <p className="alergeno-tag" style={{textDecoration: !filteredAlergenos.includes(id) ? '' : 'line-through'}} onClick={() => {filterAlergenos(id)}}>{alergenos[id]}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Alergenos;