import React, { useState } from 'react';

import "../../style/Register/Plans.css";

const SubscriptionPlan = ({ planName, price, features, setSelected, selected }) => {
    
    return (
        <div className={`subscription-plan ${selected === planName ? 'selected' : ''}`} onClick={() => setSelected(planName)}>
            <h2>{planName}</h2>
            <h3>€{price}/mes</h3>
            <ul className='subscription-features'>
            {features.map((feature, index) => (
                <li key={index}>· {feature}</li>
            ))}
            </ul>
        </div>
    );
};

const Plans = (props) => {

    const setStep = props.setStep;

    const plans = [
        {
            planName: 'Básico',
            price: '10',
            features: ['Tu carta personalizada online', 'Soporte 24/7', '2 modificaciones de cartas mensuales gratuitas', 'Primeras 10 tarjetas adhesivas con QR gratis'],
        },
        {
            planName: 'Básico Anual',
            price: '100',
            features: ['Tu carta personalizada online', 'Soporte 24/7', '2 modificaciones de cartas mensuales gratuitas', 'Primeras 10 tarjetas adhesivas con QR gratis', 'Ahorro de 20€ al año'],
        },
        {
            planName: 'Premium',
            price: '15',
            features: ['Tu carta personalizada online', 'Soporte 24/7', '5 modificaciones de cartas mensuales gratuitas', 'Primeras 15 tarjetas adhesivas con QR gratis'],
        },
        {
            planName: 'Premium Anual',
            price: '150',
            features: ['Tu carta personalizada online', 'Soporte 24/7', '5 modificaciones de cartas mensuales gratuitas', 'Primeras 15 tarjetas adhesivas con QR gratis', 'Imagenes en los productos de la carta', 'Ahorro de 30€ al año'],
        },
    ];

    const [planSelected, setPlanSelected] = useState('');

    const onSendData = () => {
        if (planSelected === '') {
            alert('Debes seleccionar un plan');
            return;
        }
        props.setFormData({
            ...props.formData,
            plan: planSelected
        });
        setStep(3);
    };

    return (
        <div className="subscription-page">
            <h1>Elige tu plan</h1>
            <div className="plans-container">
                {plans.map((plan, index) => (
                    <SubscriptionPlan setSelected={setPlanSelected} selected={planSelected} key={index} planName={plan.planName} price={plan.price} features={plan.features} />
                ))}
            </div>
            { planSelected !== '' && <button className="button-30" type="submit" onClick={() => onSendData()}>Elegir este plan</button> }
        </div>
    );
};

export default Plans;
