import React from 'react';

import '../../style/Footer.css';

function Header(props) {

    const direccion = props.direccion ?? "";
    const tfno = props.tfno ?? "";

    return (
        <div className='footer-container' style={{backgroundColor: props.backgroundColor ?? "#F5F3ED"}}>
            <p style={{marginTop: 30}}>{direccion}</p>
            <p>{tfno}</p>
            <a href="https://www.tu-carta-online.com" style={{marginBottom: 30}}>https://www.tu-carta-online.com</a>
        </div>
    );
}

export default Header;
