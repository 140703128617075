import React from 'react';

import '../style/Home.css';

const Home = () => {
  return (
    <div className='home'>
      <h2 className='header-home'>TU CARTA ONLINE</h2>
      <p className='text-home'><strong>Tu carta online</strong> es una web que permite a los bares y 
      restaurantes tener su carta online para que los clientes puedan consultarla <strong>desde cualquier lugar</strong>.</p>
    
      <p className='text-home'>Es una web muy <strong>sencilla</strong> de usar y con <strong>funciones únicas</strong>. Una vez creada, podrá 
      compartirla con sus clientes para que puedan consultarla desde su móvil.</p>
      
      <p className='text-home'>Además, contará con servicio 24 horas para poder actualizar su carta en cualquier momento.
      Por tan solo <strong>10 €/mes</strong> podrá disfrutar de un servicio permanente en la nube, con su carta.</p>
      
      <p className='text-home'>Si desea ver un ejemplo de carta, puede hacerlo en el siguiente enlace:
      </p>
      <a href='/1/Bar%20de%20Ejemplo' className='button-home'>Ir a carta de Ejemplo</a>
      <iframe src="/1/Bar%20de%20Ejemplo" title="Carta" className="mini-carta-ejemplo" />

      <p className='text-home' style={{marginTop: 75}}>Para ver un listado de todos los bares y restaurantes que tienen su carta en nuestra web, puede hacerlo en el siguiente enlace:
      </p>
      <a href='/all' className=''>Ir a listado de bares</a>
      <h3 style={{marginTop: 100}}>Contactenos</h3>
      <p>Si desea que su bar aparezca en nuestra web, contacte con nosotros en el siguiente correo electrónico:</p>
      <a href="mailto:support@tu-carta-online.com">support@tu-carta-online.com</a>
      <p>O en el teléfono:</p>
      <a href="tel:+34678623620" style={{marginBottom: 50}}>678 62 36 20</a>
    </div>
  )
}

export default Home