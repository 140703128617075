
import React, { useEffect, useState } from "react";

import "../style/allBars.css";

import axios from "axios";

const AllBars = () => {

    const [bars, setBars] = useState([]);

    const getAllBars = () => {
        axios.get(`${process.env.REACT_APP_BACK_URL}all`)
        .then((response) => {
            setBars(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        getAllBars();
    }, []);

    const onClickBar = (bar) => {
        window.location.href = `/${bar.id}/${bar.bar}`;
    }
//                            <img src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==" alt="Red dot" />

    return (
        <div className="all-bars">
            <div className="icon-container" onClick={() => {window.location.href = "/"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M12 2L2 12h3v8h6v-6h4v6h6v-8h3L12 2zm6 10h-3v6h-4v-6H9l3-3 3 3z"/>
                </svg>
            </div>
            <h2 className="header-all-bars">Bares y Restaurantes</h2>
            <p className="text-all-bars">A continuación, se muestra una lista de todos los bares y restaurantes que tienen su carta en nuestra web:</p>
            <div className="all-bars-info">
                {
                    bars.map((bar, index) => (
                        <button className="bar-info" key={index} onClick={() => onClickBar(bar)}>
                            <img src={`data:image/png;base64, ${bar.qr}`} alt="qr" width={200} />
                            <h3>{bar.name}</h3>
                            <p>{bar.direccion}</p>     
                            <p>{bar.tfno}</p>    
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

export default AllBars;

