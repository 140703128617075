import React, { useState } from "react";

import "../../style/Register/Register.css";

const Personal = (props) => {
    const [formData, setFormData] = useState({
        nombre: "",
        apellido: "",
        dni: "",
        correo: "",
        contraseña: "",
        contraseña2: ""
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    function esDniValido(dni) {
        const letras = "TRWAGMYFPDXBNJZSQVHLCKE";
    
        // Verificar la longitud y el formato
        if (dni.length !== 9 || !(/^\d{8}[a-zA-Z]$/).test(dni)) {
            return false;
        }
    
        // Extraer el número y calcular la letra
        const numero = dni.substring(0, 8);
        const letraEsperada = letras.charAt(parseInt(numero, 10) % 23);
    
        // Comparar la letra
        return letraEsperada === dni.charAt(8).toUpperCase();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.nombre === "" || formData.apellido === "" || formData.dni === "" || formData.correo === "" || formData.contraseña === "" || formData.contraseña2 === "") {
            alert("Debe completar todos los campos");
            return;
        } else if (formData.contraseña !== formData.contraseña2) {
            alert("Las contraseñas no coinciden");
            return;
        } else if (formData.contraseña.length < 8) {
            alert("La contraseña debe tener al menos 8 caracteres");
            return;
        } else if (!esDniValido(formData.dni)) {
            alert("El DNI es inválido");
            return;
        } else if (!formData.correo.includes("@")) {
            alert("El correo es inválido");
            return;
        }
        props.setFormData({
            ...props.formData,
            personal: formData
        });
        props.setStep(2);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="form-personal-data">
                <label>
                    Nombre
                    <input className="register-inputs" type="text" name="nombre" value={formData.nombre} onChange={handleChange} />
                </label>
                <br />
                <label>
                    Apellido
                    <input className="register-inputs" type="text" name="apellido" value={formData.apellido} onChange={handleChange} />
                </label>
                <br />
                <label>
                    DNI
                    <input className="register-inputs" type="text" name="dni" value={formData.dni} onChange={handleChange} />
                </label>
                <br />
                <label>
                    Correo
                    <input className="register-inputs" type="email" name="correo" value={formData.correo} onChange={handleChange} />
                </label>
                <br />
                <label>
                    Contraseña
                    <input className="register-inputs" type="password" name="contraseña" value={formData.contraseña} onChange={handleChange} />
                </label>
                <label>
                    Contraseña
                    <input className="register-inputs" type="password" name="contraseña2" value={formData.contraseña2} onChange={handleChange} />
                </label>
                <br />
                <button className="button-30" type="submit">Registrarse</button>
            </form>
        </div>
    );
};

export default Personal;