import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";

import "../style/Carta.css";

import Entrada from "../components/Carta/Entrada";
import Header from "../components/Carta/Header";
import Buscador from "../components/Carta/Buscador";
import Alergenos from "../components/Carta/Alergenos";
import Tooltip from "../components/Carta/Tooltip";
import Footer from "../components/Carta/Footer";

const Carta = (props) => {

    const barId = useParams().barId ?? 1;
    const [barName, setBarName] = useState("");
    const [alergenos, setAlergenos] = useState([]);
    const [direccion, setDireccion] = useState("");
    const [tfno, setTfno] = useState("");
    const [carta, setCarta] = useState({});
    const [divisa, setDivisa] = useState("€");
    const [filteredAlergenos, setFilteredAlergenos] = useState([]);
    const { tooltipData, tooltipOpened, setTooltipOpened, setTooltipData } = useStateContext();

    useEffect(() => {
        if (barId) {
            axios.get(`${process.env.REACT_APP_BACK_URL}alergenos`)
            .then((response) => {
                setAlergenos(response.data);
            });
            axios.get(`${process.env.REACT_APP_BACK_URL}carta/${barId}`)
            .then((response) => {
                setBarName(response.data.nombre);
                setCarta(response.data.carta);
                setDivisa(response.data.divisa);
                setDireccion(response.data.direccion);
                setTfno(response.data.telefono);
            });
        }
    }, [barId]);

    return (
        <div>
            { tooltipOpened !== "" && <Tooltip plato={tooltipOpened} data={tooltipData} alergenos={alergenos} divisa={divisa} close={() => {setTooltipOpened(''); setTooltipData({})}} /> }

            <Header barName={barName} />
            
            <Alergenos alergenos={alergenos} filteredAlergenos={filteredAlergenos} setFilteredAlergenos={setFilteredAlergenos} />

            <Buscador carta={carta} />
            
            {
                Object.keys(carta).map((item) => {
                    return (
                        <Entrada key={`entrada-${item}`} tipo={item} alergenos={alergenos} filteredAlergenos={filteredAlergenos} listado={carta[item]} divisa={divisa} cabecera={carta[item].cabecera} />
                    );
                })
            }

            <Footer direccion={direccion} tfno={tfno} />
        </div>
    );
}

export default Carta;